import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    allSanityResource(sort: {fields: publishDate, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          resource { label }
        }
      }
    }
    sanityResourcesSettings {
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const ResourceIndex = (props) => {
  const resources = props.data.allSanityResource.edges
  const { currentPage, resourcePages } = props.pageContext

  const seo = props.data.sanityResourcesSettings?.seo
  const customTitle = seo?.metaTitle ? seo.metaTitle : null
  const description = seo?.description ? seo.description : null
  const image = seo?.openGraphImage?.asset !== null ? seo?.openGraphImage?.asset?.url : null


  console.log()
  return (
    <Layout theme="light">
      <SEO
        title="Learning Analytics Resources"
        customTitle={customTitle}
        description={description}
        image={image} />
      <Hero heroTitle="Learning Analytics Resources" theme="light" graph="radar" />
      <PostWrapper>
        <PostList items={resources} theme="brandDark" type="resources" alt />
        <PostSidebar theme="brandDark" type="resources" />
      </PostWrapper>
      {resourcePages > 1 && <Pagination base="resources/" currentPage={currentPage} totalPages={resourcePages} />}
    </Layout>
  )
}

export default ResourceIndex
